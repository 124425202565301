import {
    getElementOrParentByClass,
    removeAllFromClass,
} from '../helpers/dom-helpers';
const switcherClass = 'js-lang-switcher';
const activeClass = 'is-active';

document.addEventListener('click', function (e) {
    let domElem = getElementOrParentByClass(e, switcherClass);

    if (domElem) {
        domElem.classList.toggle(activeClass);
    }
});

document.addEventListener('scroll', function (e) {
    removeAllFromClass(switcherClass, activeClass);
});
